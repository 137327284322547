import { ValidationErrorSeverity, type ValidationResult } from "@component-utils/validations";
import { computed, ref, type Ref } from "vue";
import type { ValidationProps } from "./types";
import { globalLocalize, useLocalize } from "@component-utils/localization";
import Utils from "~/features/utils";
import { resolveAccept, toContentTypes, type Accept } from "@component-utils/files";

type CreateDefaultValidatorResult<T> = (value: T | null | undefined) => ValidationResult

export function composeValidations<T> (props: ValidationProps<T>, defaultValidator?: CreateDefaultValidatorResult<T>) {
  if (!defaultValidator || !props.validator) return props.validator ?? defaultValidator

  if (props.validatorMode === 'force') {
    // Force only user validation
    return props.validator
  } else {
    // Merge validations together
    return (value: T | null | undefined) => props.validator!(value) ?? defaultValidator(value)
  }
}

export function useValidation<T> (
  validatorSubject: Ref<undefined | null | T>,
  props: ValidationProps<T>,
  defaultValidator?: CreateDefaultValidatorResult<T>
) {
  const validator = composeValidations(props, defaultValidator)

  const validationVisible = ref(props.validationTrigger === 'immediate')

  const validationResult = computed(() => validator && validator(validatorSubject.value) || null)
  const isValid = computed(() => !validationResult.value)

  const validationResultForDisplay = computed(() => validationVisible.value && validationResult.value || null)
  const isValidationError = computed(() => validationResultForDisplay.value?.[0] === ValidationErrorSeverity.Error)
  const isValidationWarning = computed(() => validationResultForDisplay.value?.[0] === ValidationErrorSeverity.Warning)

  return {
    // For display
    validationVisible,
    validationResult: validationResultForDisplay,
    isValidationError,
    isValidationWarning,
    // For expose, regardless of visibility
    isValid
  }
}

export function useComponentValidation (...refs: Ref<{ isValid: boolean }[] | { isValid: boolean } | null>[]) {
  return computed(() => refs.every((ref) => {
    const value = ref.value
    if (value) {
      if (Array.isArray(value)) {
        return value.every((item) => item.isValid)
      } else {
        return value.isValid
      }
    } else {
      return true
    }
  }))
}

export function isValidationError (validationResult: Ref<ValidationResult>) {
  return validationResult.value?.[0] === ValidationErrorSeverity.Error
}

export function isValidationWarning (validationResult: Ref<ValidationResult>) {
  return validationResult.value?.[0] === ValidationErrorSeverity.Warning
}

export function validatePasswordRequirements(rawPassword: string | null | undefined) {
  const password = (rawPassword || '').trim()
  const localize = useLocalize('component-library.validations.password.requirements')

  return [
    {
      text: localize('min_length'),
      isValid: password.length >= 10
    },
    {
      text: localize('lowercase'),
      isValid: /[a-z]/.test(password)
    },
    {
      text: localize('uppercase'),
      isValid: /[A-Z]/.test(password)
    },
    {
      text: localize('number'),
      isValid: /[0-9]/.test(password)
    },
    {
      text: localize('special_char'),
      isValid: /[!@#$%^&*(),.?":{}|<>_]/.test(password)
    }
  ]
}

export function createDefaultFileValidator (props: { required?: boolean, accept: Accept }): CreateDefaultValidatorResult<Array<File | Backend.Types.AttachedFile>> {
  return (value: Array<File | Backend.Types.AttachedFile> | undefined | null) => {
    if (!Array.isArray(value) || value.length === 0) {
      if (props.required) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
      }
      
      return null
    }

    const validContentTypes = toContentTypes(resolveAccept(props.accept))

    for (const file of (value)) {
      // For now ignore already attached file
      if ('path' in file) continue

      if (file.size >= 256e6) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.file.size')]
      }

      if (validContentTypes.length > 0 && !(validContentTypes.includes(file.type as unknown as typeof validContentTypes[number]))) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.file.type')]
      }
    }

    return null
  }
}

export function createDefaultEmailValidator (props: { required?: boolean }): CreateDefaultValidatorResult<string> {
  return (value: string | undefined | null) => {
    if (!value || !value.trim()) {
      if (props.required) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.email.empty')]
      }

      return null
    }

    if (!Utils.isEmailValid(value)) {
      return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.email.format')]
    }

    return null
  }
}

export function createDefaultPasswordValidator (props: { required?: boolean, showRequirements?: boolean }): CreateDefaultValidatorResult<string> {
  return (value: string | undefined | null) => {
    if (!value || !value.trim()) {
      if (props.required) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.password.empty')]
      }

      return null
    }

    if (!props.showRequirements && value.trim().length < 10) {
      return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.password.format')]
    }

    return null
  }
}

export function createDefaultMultiSelectValidator<T> (props: { required?: boolean }): CreateDefaultValidatorResult<T[]> {
  return (value: T[] | undefined | null) => {
    if (!value || value.length === 0) {
      if (props.required) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
      }
      
      return null
    }

    return null
  }
}

export function createDefaultOTPValidator (props: { required?: boolean }): CreateDefaultValidatorResult<string> {
  return (value: string | undefined | null) => {
    if (!value || !value.trim()) {
      if (props.required) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
      }

      return null
    }

    if (value.length < 6) {
      return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.otp.format')]
    }

    return null
  }
}

export function createDefaultNumberValidator (props: { required?: boolean, min?: number, max?: number }): CreateDefaultValidatorResult<number> {
  return (value: number | undefined | null) => {
    if (!value) {
      if (props.required) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
      }

      return null
    }

    if (typeof props.min === 'number' && value < props.min) {
      return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.number.min', { min: props.min })]
    } else if (typeof props.max === 'number' && value > props.max) {
      return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.number.max', { max: props.max })]
    }

    return null
  }
}

export function createDefaultValidator<T> (props: { required?: boolean }): CreateDefaultValidatorResult<T> {
  return (value: T | undefined | null) => {
    if (!value || (typeof value === 'string' && !value.trim())) {
      if (props.required) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
      }

      return null
    }

    return null
  }
}
